define("@ember-ui/ember-spinners/components/spinner", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <span
    class={{concat "spinner-border spinner-border-" (if @size @size "md")}}
    role="status"
    aria-hidden="true"
    ...attributes
  ></span>
  */
  {
    "id": "ptjpusPb",
    "block": "[[[11,1],[16,0,[28,[37,0],[\"spinner-border spinner-border-\",[52,[30,1],[30,1],\"md\"]],null]],[24,\"role\",\"status\"],[24,\"aria-hidden\",\"true\"],[17,2],[12],[13]],[\"@size\",\"&attrs\"],false,[\"concat\",\"if\"]]",
    "moduleName": "@ember-ui/ember-spinners/components/spinner.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});