define("ember-unique-id-helper-polyfill/helpers/unique-id", ["exports", "@ember/component/helper", "@ember/object/internals"], function (_exports, _helper, _internals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(function uniqueId() {
    return (0, _internals.guidFor)({});
  });

  _exports.default = _default;
});