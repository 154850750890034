define("ember-metrics/metrics-adapters/base", ["exports", "@ember/debug", "@ember/object/internals", "@ember/utils"], function (_exports, _debug, _internals, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function makeToString(ret) {
    return () => ret;
  }

  class BaseAdapter {
    constructor(config) {
      _defineProperty(this, "metrics", null);

      _defineProperty(this, "config", null);

      this.config = config;
    }

    install() {
      (false && !(false) && (0, _debug.assert)(`[ember-metrics] ${this.toString()} must implement the install hook!`));
    }

    uninstall() {
      (false && !(false) && (0, _debug.assert)(`[ember-metrics] ${this.toString()} must implement the uninstall hook!`));
    }

    toString() {
      const hasToStringExtension = (0, _utils.typeOf)(this.toStringExtension) === 'function';
      const extension = hasToStringExtension ? ':' + this.toStringExtension() : '';
      const ret = `ember-metrics@metrics-adapter:${extension}:${(0, _internals.guidFor)(this)}`;
      this.toString = makeToString(ret);
      return ret;
    }

    identify() {}

    trackEvent() {}

    trackPage() {}

    alias() {}

  }

  _exports.default = BaseAdapter;

  _defineProperty(BaseAdapter, "supportsFastBoot", false);
});