define("@ember-ui/ember-forms-validation/helpers/validate-confirmation", ["exports", "@ember/component/helper", "ember-changeset-validations/validators", "@ember/debug"], function (_exports, _helper, _validators, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(function validateConfirmationHelper(params, options) {
    (false && !(params.length === 0) && (0, _debug.assert)('{{validate-confirmation}} helper does not support any positional param', params.length === 0));
    return (0, _validators.validateConfirmation)( // Ember Changest Validations tries to mutate the options object passed in
    // sometimes. Ember passes the named arguments as a frozen object. To avoid
    // bugs caused by this, we clone the object.
    Object.assign({}, options));
  });

  _exports.default = _default;
});