define("@ember-ui/ember-forms/components/form", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <BsForm
    @disabled={{@disabled}}
    @elementComponent={{component "form/element"}}
    @formLayout={{@formLayout}}
    @horizontalLabelGridClass={{@horizontalLabelGridClass}}
    @onBefore={{@onBefore}}
    @onInvalid={{@onInvalid}}
    @onSubmit={{@onSubmit}}
    @preventConcurrency={{@preventConcurrency}}
    @model={{@model}}
    @readonly={{@readonly}}
    @submitButtonComponent={{component "button"}}
    ...attributes
    as |form|
  >
    {{yield form}}
  </BsForm>
  */
  {
    "id": "FnzKozPT",
    "block": "[[[8,[39,0],[[17,1]],[[\"@disabled\",\"@elementComponent\",\"@formLayout\",\"@horizontalLabelGridClass\",\"@onBefore\",\"@onInvalid\",\"@onSubmit\",\"@preventConcurrency\",\"@model\",\"@readonly\",\"@submitButtonComponent\"],[[30,2],[50,\"form/element\",0,null,null],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8],[30,9],[30,10],[50,\"button\",0,null,null]]],[[\"default\"],[[[[1,\"\\n  \"],[18,12,[[30,11]]],[1,\"\\n\"]],[11]]]]]],[\"&attrs\",\"@disabled\",\"@formLayout\",\"@horizontalLabelGridClass\",\"@onBefore\",\"@onInvalid\",\"@onSubmit\",\"@preventConcurrency\",\"@model\",\"@readonly\",\"form\",\"&default\"],false,[\"bs-form\",\"component\",\"yield\"]]",
    "moduleName": "@ember-ui/ember-forms/components/form.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});