define("@ember-ui/ember-cards/components/card", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="card {{this.bgColorClass}} {{this.fgColorClass}}" ...attributes>
    {{yield
      (hash
        header=(component "card-header")
        body=(component "card-body")
        footer=(component "card-footer")
      )
    }}
  </div>
  */
  {
    "id": "s9XH0ybt",
    "block": "[[[11,0],[16,0,[29,[\"card \",[30,0,[\"bgColorClass\"]],\" \",[30,0,[\"fgColorClass\"]]]]],[17,1],[12],[1,\"\\n  \"],[18,2,[[28,[37,1],null,[[\"header\",\"body\",\"footer\"],[[50,\"card-header\",0,null,null],[50,\"card-body\",0,null,null],[50,\"card-footer\",0,null,null]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@ember-ui/ember-cards/components/card.hbs",
    "isStrictMode": false
  });

  class CardComponent extends _component2.default {
    get cardType() {
      return this.args.type ? this.args.type.toLowerCase() : 'light';
    }

    get bgColorClass() {
      return `bg-${this.cardType}`;
    }

    get fgColorClass() {
      return this.cardType !== 'light' ? `text-white` : ``;
    }

  }

  _exports.default = CardComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CardComponent);
});