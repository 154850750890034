define("@ember-ui/ember-alerts/components/alert", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <BsAlert
    @dismissible={{@dismissible}}
    @onDismiss={{@onDismiss}}
    @onDismissed={{@onDismissed}}
    @type={{@type}}
    @visible={{@visible}}
    class={{concat
      "bg-transparent shadow border border-right-0 border-top-0 border-bottom-0 rounded-0 border-"
      (if @type @type "secondary")
    }}
    ...attributes
  >
    <:header>
      {{#if (has-block "header")}}
        <span class={{concat "text-" @type}}>
          {{yield to="header"}}
        </span>
      {{/if}}
    </:header>
  
    <:body>
      {{#if (has-block "body")}}
        {{yield to="body"}}
      {{else}}
        {{yield}}
      {{/if}}
    </:body>
  </BsAlert>
  */
  {
    "id": "RByLDvSP",
    "block": "[[[8,[39,0],[[16,0,[28,[37,1],[\"bg-transparent shadow border border-right-0 border-top-0 border-bottom-0 rounded-0 border-\",[52,[30,1],[30,1],\"secondary\"]],null]],[17,2]],[[\"@dismissible\",\"@onDismiss\",\"@onDismissed\",\"@type\",\"@visible\"],[[30,3],[30,4],[30,5],[30,1],[30,6]]],[[\"header\",\"body\"],[[[[1,\"\\n\"],[41,[48,[30,7]],[[[1,\"      \"],[10,1],[15,0,[28,[37,1],[\"text-\",[30,1]],null]],[12],[1,\"\\n        \"],[18,7,null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]],[[[1,\"\\n\"],[41,[48,[30,8]],[[[1,\"      \"],[18,8,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[18,9,null],[1,\"\\n\"]],[]]],[1,\"  \"]],[]]]]]],[\"@type\",\"&attrs\",\"@dismissible\",\"@onDismiss\",\"@onDismissed\",\"@visible\",\"&header\",\"&body\",\"&default\"],false,[\"bs-alert\",\"concat\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "@ember-ui/ember-alerts/components/alert.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});