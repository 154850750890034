define("@ember-ui/ember-forms/components/form/element/errors", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <BsForm::Element::Errors
    @messages={{@messages}}
    @show={{@show}}
    @showMultipleErrors={{@showMultipleErrors}}
  />
  
  {{#unless @show}}
    <div class="mt-1 small">
      &nbsp;
    </div>
  {{/unless}}
  */
  {
    "id": "NqGCQ2YQ",
    "block": "[[[8,[39,0],null,[[\"@messages\",\"@show\",\"@showMultipleErrors\"],[[30,1],[30,2],[30,3]]],null],[1,\"\\n\\n\"],[41,[51,[30,2]],[[[1,\"  \"],[10,0],[14,0,\"mt-1 small\"],[12],[1,\"\\n     \\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@messages\",\"@show\",\"@showMultipleErrors\"],false,[\"bs-form/element/errors\",\"unless\"]]",
    "moduleName": "@ember-ui/ember-forms/components/form/element/errors.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});