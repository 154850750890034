define("@ember-ui/ember-accordions/components/accordion", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <BsAccordion
    @onChange={{@onChange}}
    @selected={{@selected}}
    ...attributes
    as |accordion|
  >
    {{yield accordion}}
  </BsAccordion>
  */
  {
    "id": "mLf+T6fn",
    "block": "[[[8,[39,0],[[17,1]],[[\"@onChange\",\"@selected\"],[[30,2],[30,3]]],[[\"default\"],[[[[1,\"\\n  \"],[18,5,[[30,4]]],[1,\"\\n\"]],[4]]]]]],[\"&attrs\",\"@onChange\",\"@selected\",\"accordion\",\"&default\"],false,[\"bs-accordion\",\"yield\"]]",
    "moduleName": "@ember-ui/ember-accordions/components/accordion.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});