define("@ember-ui/ember-buttons/components/button", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!
    Additionally to our public arguments we also need to pass through `@state`
    and `@_disabled` arguments to use the button as submit button in `<BsForm>`.
    We consider both arguments as private API and will remove them as soon as
    Ember Bootstrap drops that requirement.
  }}
  <BsButton
    @buttonType={{@buttonType}}
    @onClick={{@onClick}}
    @preventConcurrency={{@preventConcurrency}}
    @reset={{@reset}}
    @size={{@size}}
    @type={{@type}}
    @state={{@state}}
    @_disabled={{this.disabled}}
    ...attributes
    as |button|
  >
    {{yield button}}
    {{#if button.isPending}}
      <Spinner @size="sm" />
    {{/if}}
  </BsButton>
  */
  {
    "id": "6Nbp0yxv",
    "block": "[[[8,[39,0],[[17,1]],[[\"@buttonType\",\"@onClick\",\"@preventConcurrency\",\"@reset\",\"@size\",\"@type\",\"@state\",\"@_disabled\"],[[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8],[30,0,[\"disabled\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[18,10,[[30,9]]],[1,\"\\n\"],[41,[30,9,[\"isPending\"]],[[[1,\"    \"],[8,[39,3],null,[[\"@size\"],[\"sm\"]],null],[1,\"\\n\"]],[]],null]],[9]]]]]],[\"&attrs\",\"@buttonType\",\"@onClick\",\"@preventConcurrency\",\"@reset\",\"@size\",\"@type\",\"@state\",\"button\",\"&default\"],false,[\"bs-button\",\"yield\",\"if\",\"spinner\"]]",
    "moduleName": "@ember-ui/ember-buttons/components/button.hbs",
    "isStrictMode": false
  });

  class ButtonComponent extends _component2.default {
    // Using `{{@_disabled}}` in template throws a compile time error:
    // Assertion Failed: '@_disabled' is reserved.
    // Need to map it to another variable name therefore.
    get disabled() {
      return this.args._disabled;
    }

  }

  _exports.default = ButtonComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ButtonComponent);
});