define("@ember-ui/ember-forms/components/form/element", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-implicit-this }}
  {{!
    We use our own form element component only for one reason: To set a custom
    @errorComponent on it. All the other code in this file is only boilerplate
    code required to extends <BsForm::Element>.
  
    All public arguments of <BsForm::Element> are passed through except:
  
    - @errorIcon
    - @showMultipleErrors
    - @successIcon
    - @warningIcon
  
    We don't want to support these arguments as their only use case is to
    change the design in a way which is not compliant with Omnivise User
    Interface Guidelines.
  
    Public arguments are listed here:
    https://www.ember-bootstrap.com/api/classes/Components.FormElement.html
  
    Additionally <BsForm> sets some internal arguments on <BsForm::Element>
    when yielding the component. We need to pass them through as well.
    https://github.com/kaliber5/ember-bootstrap/blob/19ded7030e6de572521595e026bdad7c955381ec/addon/components/bs-form.hbs#L12-L21
  
    An Argument can not be referenced with `@` in a template if their name
    starts with an underscore. Need to proxy these arguments trough using a
    getter on the backing JavaScript class.
  }}
  
  <BsForm::Element
    @errorsComponent={{if
      @disableCustomErrorComponent
      null
      (component "form/element/errors")
    }}
    @controlType={{@controlType}}
    @customError={{@customError}}
    @customWarning={{@customWarning}}
    @doNotShowValidationForEventTargets={{@doNotShowValidationForEventTargets}}
    @formLayout={{@formLayout}}
    @helpText={{@helpText}}
    @horizontalLabelGridClass={{@horizontalLabelGridClass}}
    @infoIcon={{@infoIcon}}
    @invisibleLabel={{@invisibleLabel}}
    @label={{@label}}
    @model={{@model}}
    @onChange={{@onChange}}
    @optionLabelPath={{@optionLabelPath}}
    @options={{@options}}
    @property={{@property}}
    @showValidationOn={{@showValidationOn}}
    @size={{@size}}
    @useIcons={{@useIcons}}
    @value={{@value}}
    @showAllValidations={{@showAllValidations}}
    @_disabled={{this._disabled}}
    @_readonly={{this._readonly}}
    @_onChange={{this._onChange}}
    ...attributes
    as |element|
  >
    {{!
      <BsForm::Element> behaves differently depending if invoked with or without
      block. As we force it to be always used in block-mode we need to replicate
      that behavior ourself.
      https://github.com/kaliber5/ember-bootstrap/blob/19ded7030e6de572521595e026bdad7c955381ec/addon/components/bs-form/element.hbs#L111-L123
    }}
    {{#if (has-block)}}
      {{yield element}}
    {{else}}
      <element.control />
    {{/if}}
  </BsForm::Element>
  */
  {
    "id": "5/nW3H5y",
    "block": "[[[1,\"\\n\"],[8,[39,0],[[17,1]],[[\"@errorsComponent\",\"@controlType\",\"@customError\",\"@customWarning\",\"@doNotShowValidationForEventTargets\",\"@formLayout\",\"@helpText\",\"@horizontalLabelGridClass\",\"@infoIcon\",\"@invisibleLabel\",\"@label\",\"@model\",\"@onChange\",\"@optionLabelPath\",\"@options\",\"@property\",\"@showValidationOn\",\"@size\",\"@useIcons\",\"@value\",\"@showAllValidations\",\"@_disabled\",\"@_readonly\",\"@_onChange\"],[[52,[30,2],null,[50,\"form/element/errors\",0,null,null]],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8],[30,9],[30,10],[30,11],[30,12],[30,13],[30,14],[30,15],[30,16],[30,17],[30,18],[30,19],[30,20],[30,21],[30,22],[30,0,[\"_disabled\"]],[30,0,[\"_readonly\"]],[30,0,[\"_onChange\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,24]],[[[1,\"    \"],[18,24,[[30,23]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[30,23,[\"control\"]],null,null,null],[1,\"\\n\"]],[]]]],[23]]]]]],[\"&attrs\",\"@disableCustomErrorComponent\",\"@controlType\",\"@customError\",\"@customWarning\",\"@doNotShowValidationForEventTargets\",\"@formLayout\",\"@helpText\",\"@horizontalLabelGridClass\",\"@infoIcon\",\"@invisibleLabel\",\"@label\",\"@model\",\"@onChange\",\"@optionLabelPath\",\"@options\",\"@property\",\"@showValidationOn\",\"@size\",\"@useIcons\",\"@value\",\"@showAllValidations\",\"element\",\"&default\"],false,[\"bs-form/element\",\"if\",\"component\",\"has-block\",\"yield\"]]",
    "moduleName": "@ember-ui/ember-forms/components/form/element.hbs",
    "isStrictMode": false
  });

  class FormElementComponent extends _component2.default {
    get _disabled() {
      return this.args._disabled;
    }

    get _readonly() {
      return this.args._readonly;
    }

    get _onChange() {
      return this.args._onChange;
    }

  }

  _exports.default = FormElementComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormElementComponent);
});