define("@ember-ui/ember-icons/utils/icon-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.iconSize = _exports.iconMap = void 0;
  const iconMap = {
    email: {
      faIcon: 'envelope',
      prefix: 'far'
    },
    'siemens-id': {
      faIcon: 'address-card',
      prefix: 'far'
    },
    'pki-card': {
      faIcon: 'sim-card',
      prefix: 'fas'
    },
    'notification-bell': {
      faIcon: 'bell',
      prefix: 'far'
    },
    'external-link': {
      faIcon: 'up-right-from-square',
      prefix: 'fas'
    },
    eye: {
      faIcon: 'eye',
      prefix: 'fas'
    },
    'eye-slash': {
      faIcon: 'eye-slash',
      prefix: 'fas'
    },
    information: {
      faIcon: 'circle-info',
      prefix: 'fas'
    },
    'module-launcher': {
      faIcon: 'table-cells',
      prefix: 'fas'
    },
    headset: {
      faIcon: 'headset',
      prefix: 'fas'
    },
    close: {
      faIcon: 'xmark',
      prefix: 'fas'
    },
    phone: {
      faIcon: 'phone-flip',
      prefix: 'fas'
    },
    add: {
      faIcon: 'plus',
      prefix: 'fas'
    },
    file: {
      faIcon: 'file-lines',
      prefix: 'fas'
    },
    report: {
      faIcon: 'file',
      prefix: 'far'
    },
    comment: {
      faIcon: 'message',
      prefix: 'far'
    },
    trash: {
      faIcon: 'trash',
      prefix: 'fas'
    },
    exclamation: {
      faIcon: 'circle-exclamation',
      prefix: 'fas'
    },
    lock: {
      faIcon: 'lock',
      prefix: 'fas'
    },
    'sign-out': {
      faIcon: 'right-from-bracket',
      prefix: 'fas'
    },
    'user-circle': {
      faIcon: 'circle-user',
      prefix: 'far'
    },
    pencil: {
      faIcon: 'pencil',
      prefix: 'fas'
    }
  };
  _exports.iconMap = iconMap;
  const iconSize = ['xs', 'sm', 'md', 'lg'];
  _exports.iconSize = iconSize;
});