define("@ember-ui/ember-icons/components/icon", ["exports", "@ember/component", "@glimmer/component", "@ember/debug", "@ember-ui/ember-icons/utils/icon-map", "@ember/template-factory"], function (_exports, _component, _component2, _debug, _iconMap, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <FaIcon
    @icon={{this.icon}}
    @size={{this.size}}
    @prefix={{this.prefix}}
    ...attributes
  />
  */
  {
    "id": "YyF6sn+K",
    "block": "[[[8,[39,0],[[17,1]],[[\"@icon\",\"@size\",\"@prefix\"],[[30,0,[\"icon\"]],[30,0,[\"size\"]],[30,0,[\"prefix\"]]]],null]],[\"&attrs\"],false,[\"fa-icon\"]]",
    "moduleName": "@ember-ui/ember-icons/components/icon.hbs",
    "isStrictMode": false
  });

  class IconComponent extends _component2.default {
    get prefix() {
      return _iconMap.iconMap[this.args.icon]?.prefix;
    }

    get icon() {
      (false && !(Object.keys(_iconMap.iconMap).includes(this.args.icon)) && (0, _debug.assert)(`${this.args.icon} passed as @icon to <Icon> is not a supported value.`, Object.keys(_iconMap.iconMap).includes(this.args.icon)));
      return _iconMap.iconMap[this.args.icon]?.faIcon;
    }

    get size() {
      (false && !(_iconMap.iconSize.includes(this.args.size) || !this.args.size) && (0, _debug.assert)(`${this.args.size} passed as @size to <Icon> is not a supported value.`, _iconMap.iconSize.includes(this.args.size) || !this.args.size));
      return this.args.size;
    }

  }

  _exports.default = IconComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, IconComponent);
});