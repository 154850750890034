define("@ember-ui/ember-modals/components/modal", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <BsModal
    @open={{@open}}
    @backdropClose={{@backdropClose}}
    @onSubmit={{@onSubmit}}
    @onHidden={{@onHidden}}
    @onShow={{@onShow}}
    @onShown={{@onShown}}
    @size={{@size}}
    ...attributes
    as |modal|
  >
    {{yield
      (hash
        header=(component
          "modal-header"
          onClose=modal.close
          closeButton=(if (eq @closeButton false) false true)
        )
        body=(component "modal-body")
        footer=(component "modal-footer")
        close=modal.close
        submit=modal.submit
      )
    }}
  </BsModal>
  */
  {
    "id": "+aO8TMD6",
    "block": "[[[8,[39,0],[[17,1]],[[\"@open\",\"@backdropClose\",\"@onSubmit\",\"@onHidden\",\"@onShow\",\"@onShown\",\"@size\"],[[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8]]],[[\"default\"],[[[[1,\"\\n  \"],[18,11,[[28,[37,2],null,[[\"header\",\"body\",\"footer\",\"close\",\"submit\"],[[50,\"modal-header\",0,null,[[\"onClose\",\"closeButton\"],[[30,9,[\"close\"]],[52,[28,[37,5],[[30,10],false],null],false,true]]]],[50,\"modal-body\",0,null,null],[50,\"modal-footer\",0,null,null],[30,9,[\"close\"]],[30,9,[\"submit\"]]]]]]],[1,\"\\n\"]],[9]]]]]],[\"&attrs\",\"@open\",\"@backdropClose\",\"@onSubmit\",\"@onHidden\",\"@onShow\",\"@onShown\",\"@size\",\"modal\",\"@closeButton\",\"&default\"],false,[\"bs-modal\",\"yield\",\"hash\",\"component\",\"if\",\"eq\"]]",
    "moduleName": "@ember-ui/ember-modals/components/modal.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});