define("@ember-ui/ember-forms-validation/helpers/validate-presence", ["exports", "@ember/component/helper", "ember-changeset-validations/validators", "@ember/debug"], function (_exports, _helper, _validators, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(function validatePresenceHelper(_ref, options) {
    let [presence] = _ref;
    // validatePresence has only one argument. It's either a configuration object
    // or a boolean value as a shortcut for `{ presence: value }`.
    // https://github.com/poteto/ember-changeset-validations#presence
    (false && !(presence === undefined || Object.keys(options).length === 0) && (0, _debug.assert)('{{validate-presence}} helper can be either used with named arguments or one positional param. Not with both', presence === undefined || Object.keys(options).length === 0));
    return (0, _validators.validatePresence)(typeof presence === 'boolean' ? presence : // Ember Changest Validations tries to mutate the options object passed in
    // sometimes. Ember passes the named arguments as a frozen object. To avoid
    // bugs caused by this, we clone the object.
    Object.assign({}, options));
  });

  _exports.default = _default;
});