define("@ember-ui/ember-dropdown/components/dropdown", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <BsDropdown
    @closeOnMenuClick={{@closeOnMenuClick}}
    @direction={{@direction}}
    @onHide={{if @onHide @onHide this.dummyAction}}
    @onShow={{if @onShow @onShow this.dummyAction}}
    ...attributes
    as |dd|
  >
    {{yield dd}}
  </BsDropdown>
  */
  {
    "id": "Lh/Wkpf/",
    "block": "[[[8,[39,0],[[17,1]],[[\"@closeOnMenuClick\",\"@direction\",\"@onHide\",\"@onShow\"],[[30,2],[30,3],[52,[30,4],[30,4],[30,0,[\"dummyAction\"]]],[52,[30,5],[30,5],[30,0,[\"dummyAction\"]]]]],[[\"default\"],[[[[1,\"\\n  \"],[18,7,[[30,6]]],[1,\"\\n\"]],[6]]]]]],[\"&attrs\",\"@closeOnMenuClick\",\"@direction\",\"@onHide\",\"@onShow\",\"dd\",\"&default\"],false,[\"bs-dropdown\",\"if\",\"yield\"]]",
    "moduleName": "@ember-ui/ember-dropdown/components/dropdown.hbs",
    "isStrictMode": false
  });

  let ButtonComponent = (_class = class ButtonComponent extends _component2.default {
    dummyAction() {
      return;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "dummyAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "dummyAction"), _class.prototype)), _class);
  _exports.default = ButtonComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ButtonComponent);
});