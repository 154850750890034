define("@ember-ui/ember-forms/components/bs-form/element/control/select", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <select
    class="form-select
      {{if
        (eq @validationType 'error')
        'is-invalid'
        (if
          (eq @validationType 'success')
          'is-valid'
          (if (eq @validationType 'warning') 'is-warning')
        )
      }}"
    id={{@id}}
    aria-describedby={{this.ariaDescribedBy}}
    {{on "change" this.handleChange}}
  >
    {{#if @placeholder}}
      <option disabled selected>
        {{@placeholder}}
      </option>
    {{/if}}
    {{#each @options as |opt|}}
      <option class="dropdown-item" selected={{bs-eq opt @value}}>
        {{#if (has-block)}}
          {{yield opt}}
        {{else}}
          {{opt}}
        {{/if}}
      </option>
    {{/each}}
  </select>
  */
  {
    "id": "CuYkx2kJ",
    "block": "[[[11,\"select\"],[16,0,[29,[\"form-select\\n    \",[52,[28,[37,1],[[30,1],\"error\"],null],\"is-invalid\",[52,[28,[37,1],[[30,1],\"success\"],null],\"is-valid\",[52,[28,[37,1],[[30,1],\"warning\"],null],\"is-warning\"]]]]]],[16,1,[30,2]],[16,\"aria-describedby\",[30,0,[\"ariaDescribedBy\"]]],[4,[38,2],[\"change\",[30,0,[\"handleChange\"]]],null],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"    \"],[10,\"option\"],[14,\"disabled\",\"\"],[14,\"selected\",\"\"],[12],[1,\"\\n      \"],[1,[30,3]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[42,[28,[37,4],[[28,[37,4],[[30,4]],null]],null],null,[[[1,\"    \"],[10,\"option\"],[14,0,\"dropdown-item\"],[15,\"selected\",[28,[37,5],[[30,5],[30,6]],null]],[12],[1,\"\\n\"],[41,[48,[30,7]],[[[1,\"        \"],[18,7,[[30,5]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,5]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[5]],null],[13]],[\"@validationType\",\"@id\",\"@placeholder\",\"@options\",\"opt\",\"@value\",\"&default\"],false,[\"if\",\"eq\",\"on\",\"each\",\"-track-array\",\"bs-eq\",\"has-block\",\"yield\"]]",
    "moduleName": "@ember-ui/ember-forms/components/bs-form/element/control/select.hbs",
    "isStrictMode": false
  });

  let BsFormElementControlSelect = (_class = class BsFormElementControlSelect extends _component2.default {
    handleChange(e) {
      const {
        placeholder,
        options,
        onChange
      } = this.args;
      const selectEl = e.target;
      let {
        selectedIndex
      } = selectEl;

      if (placeholder) {
        selectedIndex--;
      }

      onChange(options[selectedIndex]);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "handleChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleChange"), _class.prototype)), _class);
  _exports.default = BsFormElementControlSelect;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BsFormElementControlSelect);
});