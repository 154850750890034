define("ember-file-upload/services/file-queue", ["exports", "@ember/debug", "@ember/array", "@ember/service", "@ember/runloop", "ember-file-upload/queue", "ember-file-upload/mixins/with-files"], function (_exports, _debug, _array, _service, _runloop, _queue, _withFiles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
    The file queue service is a global file
    queue that manages all files being uploaded.
  
    This service can be used to query the current
    upload state when a user leaves the app,
    asking them whether they want to cancel
    the remaining uploads.
  
    @class FileQueue
    @extends Ember.Service
   */
  class FileQueueService extends _service.default.extend(_withFiles.default) {
    constructor() {
      super(...arguments);

      _defineProperty(this, "queues", (0, _array.A)([]));

      /**
        The list of all files in queues. This automatically gets
        flushed when all the files in the queue have settled.
         Note that files that have failed need to be manually
        removed from the queue. This is so they can be retried
        without resetting the state of the queue, orphaning the
        file from its queue. Upload failures can happen due to a
        timeout or a server response. If you choose to use the
        `abort` method, the file will fail to upload, but will
        be removed from the requeuing proccess, and will be
        considered to be in a settled state.
         @property files
        @type {File[]}
        @default []
       */
      _defineProperty(this, "files", (0, _array.A)([]));
    }

    /**
      Returns a queue with the given name
       @method find
      @param {String} name The name of the queue to find
      @return {Queue} The queue or null if it doesn't exist yet.
     */
    find(name) {
      return this.queues.findBy('name', name);
    }
    /**
      Create a new queue with the given name.
       @method create
      @param {String} name The name of the queue to create
      @return {Queue} The new queue.
     */


    create(name) {
      (false && !(this.find(name) == null) && (0, _debug.assert)(`Queue names are required to be unique. "${name}" has already been reserved.`, this.find(name) == null));

      let queue = _queue.default.create({
        name,
        fileQueue: this
      });

      this.queues.push(queue);
      (0, _runloop.once)(this, 'notifyPropertyChange', 'queues');
      return queue;
    }

  }

  _exports.default = FileQueueService;
});