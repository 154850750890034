define("@ember-ui/ember-modals/components/modal-header", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="modal-header text-muted" ...attributes>
    {{yield}}
    {{#if @closeButton}}
      <Button class="btn-close" @onClick={{@onClose}} />
    {{/if}}
  </div>
  */
  {
    "id": "OaPcOsOS",
    "block": "[[[11,0],[24,0,\"modal-header text-muted\"],[17,1],[12],[1,\"\\n  \"],[18,4,null],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[8,[39,2],[[24,0,\"btn-close\"]],[[\"@onClick\"],[[30,3]]],null],[1,\"\\n\"]],[]],null],[13]],[\"&attrs\",\"@closeButton\",\"@onClose\",\"&default\"],false,[\"yield\",\"if\",\"button\"]]",
    "moduleName": "@ember-ui/ember-modals/components/modal-header.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});