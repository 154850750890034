define("@ember-ui/ember-cards/components/card-body", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="card-body" ...attributes>
    {{yield
      (hash title=(component "card-body-title") text=(component "card-body-text"))
    }}
  </div>
  */
  {
    "id": "GC3ZWHGG",
    "block": "[[[11,0],[24,0,\"card-body\"],[17,1],[12],[1,\"\\n  \"],[18,2,[[28,[37,1],null,[[\"title\",\"text\"],[[50,\"card-body-title\",0,null,null],[50,\"card-body-text\",0,null,null]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@ember-ui/ember-cards/components/card-body.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});