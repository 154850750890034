define("@ember-ui/ember-cookie-notifier/components/cookie-notifier", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/application", "@ember/template-factory"], function (_exports, _component, _component2, _object, _tracking, _application, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.shouldShowCookieNotice}}
    <div class="cookie-notifier bg-white pt-2 mt-auto ms-0 mb-0 me-0">
      <div
        class="d-flex flex-column flex-md-row align-items-center justify-content-center mx-auto col-sm-10"
      >
        <div class="text-center">
          <h6>
            Cookie Notice
          </h6>
          <p class="text-secondary" data-test-cookie-notice>
            We (Siemens Energy) and certain third parties use
            {{#if this.usesLocalRoutes}}
              <LinkTo @route="cookies" target="_blank" data-test-learn-more>
                cookies
              </LinkTo>
            {{else}}
              <a
                href={{this.cookies}}
                target="_blank"
                rel="noopener noreferrer"
                data-test-learn-more
              >
                cookies
              </a>
            {{/if}}
            on siemens-energy.com.
          </p>
        </div>
        <div class="d-flex flex-column ms-md-4 mt-md-n4">
          {{#if @shouldShowDeclineOption}}
            <Button
              @type="link"
              class="text-dark fw-bold text-decoration-none"
              data-test-decline-cookies
              @onClick={{@onDecline}}
            >
              Decline
            </Button>
          {{/if}}
          <Button
            @type="primary"
            data-test-accept-cookies
            @onClick={{this.accept}}
          >
            Accept
          </Button>
        </div>
      </div>
    </div>
  {{/if}}
  */
  {
    "id": "6kTnqIpU",
    "block": "[[[41,[30,0,[\"shouldShowCookieNotice\"]],[[[1,\"  \"],[10,0],[14,0,\"cookie-notifier bg-white pt-2 mt-auto ms-0 mb-0 me-0\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"d-flex flex-column flex-md-row align-items-center justify-content-center mx-auto col-sm-10\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"text-center\"],[12],[1,\"\\n        \"],[10,\"h6\"],[12],[1,\"\\n          Cookie Notice\\n        \"],[13],[1,\"\\n        \"],[10,2],[14,0,\"text-secondary\"],[14,\"data-test-cookie-notice\",\"\"],[12],[1,\"\\n          We (Siemens Energy) and certain third parties use\\n\"],[41,[30,0,[\"usesLocalRoutes\"]],[[[1,\"            \"],[8,[39,1],[[24,\"target\",\"_blank\"],[24,\"data-test-learn-more\",\"\"]],[[\"@route\"],[\"cookies\"]],[[\"default\"],[[[[1,\"\\n              cookies\\n            \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"            \"],[10,3],[15,6,[30,0,[\"cookies\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[14,\"data-test-learn-more\",\"\"],[12],[1,\"\\n              cookies\\n            \"],[13],[1,\"\\n\"]],[]]],[1,\"          on siemens-energy.com.\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"d-flex flex-column ms-md-4 mt-md-n4\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"          \"],[8,[39,2],[[24,0,\"text-dark fw-bold text-decoration-none\"],[24,\"data-test-decline-cookies\",\"\"]],[[\"@type\",\"@onClick\"],[\"link\",[30,2]]],[[\"default\"],[[[[1,\"\\n            Decline\\n          \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"        \"],[8,[39,2],[[24,\"data-test-accept-cookies\",\"\"]],[[\"@type\",\"@onClick\"],[\"primary\",[30,0,[\"accept\"]]]],[[\"default\"],[[[[1,\"\\n          Accept\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@shouldShowDeclineOption\",\"@onDecline\"],false,[\"if\",\"link-to\",\"button\"]]",
    "moduleName": "@ember-ui/ember-cookie-notifier/components/cookie-notifier.hbs",
    "isStrictMode": false
  });

  let CookieNotifierComponent = (_class = class CookieNotifierComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "_shouldShowNotice", _descriptor, this);
    }

    get config() {
      return (0, _application.getOwner)(this).resolveRegistration('config:environment');
    }

    get environment() {
      return this.config.environment;
    }

    get baseUrl() {
      switch (this.environment) {
        case 'production':
          return 'https://one-energy.mindsphere.siemens.com';

        case 'qa-server':
          return 'https://one-energy-qa.mindsphere.siemens.com';

        default:
          return 'https://one-energy-dev.mindsphere.siemens.com';
      }
    }

    get cookies() {
      return `${this.baseUrl}/cookies`;
    }

    get usesLocalRoutes() {
      return this.config['@ember-ui/ember-cookie-notifier']?.usesLocalRoutes === true;
    }

    get shouldShowCookieNotice() {
      const cookiesWereAccepted = window.localStorage.getItem('cookiesAccepted');
      return this._shouldShowNotice && !cookiesWereAccepted;
    }

    accept() {
      window.localStorage.setItem('cookiesAccepted', true);
      this._shouldShowNotice = false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_shouldShowNotice", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "accept", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "accept"), _class.prototype)), _class);
  _exports.default = CookieNotifierComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CookieNotifierComponent);
});