define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["de-de", {
    "brand": {
      "omnivise": "Omnivise",
      "siemens-energy": "Siemens Energy",
      "slogan": "We energize society"
    },
    "form": {
      "button": {
        "back-to-login": "Zurück zur Anmeldung",
        "login": "Einloggen",
        "logout": "Ausloggen",
        "submit": "Einreichen",
        "submitting": "Einreichen"
      },
      "label": {
        "email": "E-Mail",
        "password": "Passwort"
      },
      "placeholder": {
        "email": "Geben Sie bitte Ihre Email-Adresse ein.",
        "password": "Bitte geben Sie Ihr Passwort ein."
      },
      "validation-error": {
        "email": "{description} muss eine gültige E-Mail-Adresse sein.",
        "required": "{description} darf nicht leer sein."
      }
    },
    "screen": {
      "login": {
        "contact-us": {
          "title": "Kontaktiere uns"
        },
        "email-and-password": {
          "pki-login": "PKI Einloggen",
          "siemens-id-login": "Siemens ID Einloggen",
          "title": "Einloggen"
        },
        "main": {
          "unknown-state-instruction": "Melden Sie sich ab und versuchen Sie es später erneut.",
          "unknown-state-instruction-details": "Wenn der Fehler weiterhin besteht, wenden Sie sich bitte an den Support.",
          "unknown-state-title": "Die Authentifizierung hat einen unbekannten Status erreicht."
        }
      }
    }
  }], ["en-us", {
    "brand": {
      "siemens-energy": "Siemens Energy",
      "slogan": "we energize society"
    },
    "cookies": {
      "setting-button": "Settings"
    },
    "form": {
      "button": {
        "back-to-login": "Back to Login",
        "contact-support": "Contact Support",
        "logout": "Log out",
        "send": "Send",
        "sending": "Sending",
        "submit": "Submit",
        "submitting": "Submitting"
      },
      "label": {
        "company-name": "Company Name",
        "email": "E-Mail",
        "full-name": "Full Name",
        "message": "Message"
      },
      "placeholder": {
        "email": "Please enter your email address.",
        "password": "Please enter your password."
      },
      "validation-error": {
        "email": "{description} must be a valid email address",
        "max-length": "{description} is too long (maximum is {max} characters)",
        "required": "{description} can't be blank"
      }
    },
    "header": {
      "modules-launcher": "Apps",
      "notifications": "Notifications",
      "show-tooltips": "Information and Tooltips"
    },
    "heading-for-module": {
      "about": "Corporate Information",
      "auth": {
        "index": "Welcome!"
      },
      "cookies": "Cookie Notice",
      "privacy": "Privacy Notice",
      "terms-of-use": "Terms Of Use"
    },
    "help-materials": {
      "description-message": "Here you can find useful help documentation and training materials.",
      "documentation": "Documentation",
      "select-module": "Select Module",
      "trainings": "Trainings",
      "welcome-message": "Welcome to Help Materials"
    },
    "notification": {
      "failed-sensor-comment": "{unitName} new failed sensor comment",
      "no-notification-image-alt": "No notifications",
      "no-notification-message": "You have no notifications.",
      "pds-report-comment": "{unitName} new PDS report comment",
      "report-comment": "{unitName} new {applicationDescription} report"
    },
    "omnivise-tickets": {
      "attachments": "Attachments",
      "back": "Back",
      "browse-files": "Browse files",
      "cancel": "Cancel",
      "case-description": "Please include a thorough description of your issue and its impact, i.e.: Sensor data for Foobar Unit 42 is missing in Application X under Tab M.",
      "create-ticket": "Create ticket",
      "crop-screenshots-modal-section": {
        "body": {
          "alt-screenshot-img": "Screenshot",
          "help-text": "If you want to crop the screenshot to focus on a particular area, please reposition the crop markers."
        },
        "footer": {
          "attach": "Attach",
          "cancel": "Cancel"
        },
        "header": {
          "screenshot": "Screenshot"
        }
      },
      "description": "Description",
      "description-message": "We help you solve bugs and issues within our application environment.",
      "drag-drop-message": "Drag & drop files here or",
      "drop-to-upload": "Drop to upload",
      "file-size-invalid": "File size is greater than 10 MB",
      "file-type-invalid": "Invalid file format",
      "file-types": "File types: .jpeg , .png , .pdf, .txt",
      "floating-icon-alt": "My Tickets",
      "header-icon-alt": "My Tickets",
      "launch-button-title": "Help & Tickets",
      "loading-failed": {
        "contact-us": "Contact us",
        "explanation": "Something went wrong at our end.",
        "explanation-2": "Don't worry, it's not you. It's us.",
        "explanation-3": "Please try again later.",
        "title": "Error!"
      },
      "max-file-size-mb": "Max file size: 10 MB",
      "my-tickets": "My tickets",
      "or": "or",
      "priority": "Priority",
      "question": "How can we help you?",
      "request-type": "Request type",
      "select": "Select",
      "server-error": "Something went wrong.",
      "success-message": {
        "case-id": "Ticket ID: {caseId}",
        "explanation": "Your ticket has been successfully submitted",
        "title": "Submitted!"
      },
      "take-screenshots": "Take screenshots",
      "take-screenshots-help": {
        "capture": "Capture",
        "point-1": "You have the option to capture the entire screen, a window, or a browser tab.",
        "point-2": "Choose the screen/window/tab you need to describe the bug/error.",
        "point-3": "Accept the screenshot or create a new one.",
        "point-4": "You can crop it after capturing.",
        "point-5": "The screenshot will be added to the list of files.",
        "point-6": "Click on the Capture button when ready.",
        "title": "How to use it?"
      },
      "take-screenshots-help-content": "Please follow the instructions to capture all the issues on the screen",
      "take-screenshots-help-header": "Screen Capture",
      "title": "Title",
      "uploading": "Uploading {length} files. ({progress})%",
      "welcome-message": "Welcome to My Tickets"
    },
    "screen": {
      "internal-view": {
        "loading-failed": {
          "explanation": "Could not load the module. Please double check your network connection and try again.",
          "title": "Error!"
        },
        "not-available": {
          "explanation": "Either you are not authorized to view this content, or it is not available.",
          "title": "Error!"
        }
      },
      "login": {
        "contact-us": {
          "error-message": {
            "explanation": "Something went wrong at our end.",
            "explanation-2": "We are sorry about that.",
            "title": "Error!"
          },
          "success-message": {
            "details": "One of our representatives will contact you soon.",
            "thank-you": "Thank you for reaching out to us!",
            "title": "Message sent!"
          },
          "title": "Contact us"
        },
        "main-options": {
          "azure": "Login",
          "keycloak": "Login Keycloak",
          "okta": "Login Okta",
          "testIdp": "Login Test IDP"
        },
        "secondary-options": {
          "azurefederated": "Legacy Login",
          "keycloak": "Login Keycloak",
          "okta": "Legacy Okta Login",
          "testIdp": "Login Test IDP",
          "title": "Others"
        },
        "token-expired": {
          "error-message": {
            "explanation": "Something went wrong at our end.",
            "explanation-2": "You were unexpectedly logged out of the application.",
            "explanation-3": "Please try again later.",
            "title": "Error!"
          }
        }
      },
      "modules": {
        "alt-module-screenshot": "Module screenshot image",
        "at": "at",
        "auth-scope": "Auth Scope",
        "benefits-title": "Benefits",
        "cd-id": "Component Descriptor",
        "cd-version": "VC Version",
        "created": "Created At",
        "empty-modules-list-message": "There are no modules setup for your account",
        "empty-search-results-message": "No modules found for search term",
        "for-more-info-reach": "For more info please reach",
        "group-by-category": "Group by category",
        "icon": "Module Icon",
        "icons": "Applications Icon",
        "module-info": "Module Info",
        "quick-access": "Quick access",
        "release-channel": "Release channel",
        "search": "Search Modules",
        "uncategorized": "Uncategorized",
        "updated": "Updated At",
        "vcs-info": "Visualization components info",
        "view-id": "View Id",
        "view-type": "View Type",
        "views-info": "Views info"
      }
    },
    "userMenu": {
      "logout": "Logout",
      "title": "User details"
    }
  }]];
  _exports.default = _default;
});